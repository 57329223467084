<template>
	<div>
		<b-row>
			<b-col sm="12" class="mb-3">
				<b>ASSET LOCATION MAP</b>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="4" sm="6" class="my-1">
				<b-form-group label="Asset Type">
					<v-select name="Asset Type" class="style-chooser" label="text" placeholder="-Please Select-" :options="filteredAssetTypeOptions"
						:reduce="(assetType) => assetType.value" v-model="filterBy.assetType"
						v-validate="'selectRequired'">
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for an asset type
							</em>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
			<b-col md="4" sm="6" class="my-1">
				<b-form-group label="Connection" description="Company">
					<v-select name="Company" class="style-chooser" label="text" placeholder="-Please Select-" :options="filteredCompanyOptions"
						:reduce="(company) => company.value" v-model="filterBy.company" v-validate="'selectRequired'">
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a company
							</em>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
			<b-col md="4" sm="6" class="my-1">
				<b-form-group description="Storage Location" class="field-without-label">
					<v-select name="Storage Location" class="style-chooser" label="text" placeholder="-Please Select-"
						:options="filteredLocationOptions" :reduce="(loc) => loc.value"
						v-model="filterBy.storageLocation" v-validate="'selectRequired'">
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a location
							</em>
						</template>
					</v-select>
				</b-form-group>
			</b-col>
		</b-row>

		<template v-if="!currentLocation.location">
			<b-alert v-if="currentLocation.isGettingLocation" show>
				Getting your location...
			</b-alert>
			<b-alert v-if="currentLocation.error" variant="danger" show>
				Sorry, but the following error occurred:
				<strong>{{ currentLocation.error }}</strong>
			</b-alert>
		</template>
		<template v-else>
			<gmap-map :center="center" :zoom="12" :style="{ width: '100%', height: '400px' }" :options="mapConfig">
				<gmap-marker v-for="(marker, index) in markers" :key="index" :position="marker.position"
					:clickable="true" @click="toggleInfoWindow(index)">
					<gmap-info-window @closeclick="closeInfoWindow(index)" :opened="marker.visible">
						<b class="font-weight-bold">{{ marker.label.text }}</b>

						<div class="total-assets">
							Total Assets: <b>{{ getTotal(marker) }}</b>
						</div>

						<div class="distribution" v-show="marker.assetDistribution.total > 0">
							Distribution:
							<div v-for="(assetType, index) in marker.assetDistribution.assetTypes" :key="index">
								<i class="icon-info"></i>&nbsp;
								<b>{{ assetType.assetType + ": " + assetType.perAssetTypeTotalCount }}</b>
							</div>
						</div>
					</gmap-info-window>
				</gmap-marker>
			</gmap-map>
			<span class="total-display">Search Results: {{ assetLocationFilteredOptions.length }}</span>
		</template>
	</div>
</template>

<script>
import config from '@/config/env-constants';
import _ from 'lodash';

export default {
	name: 'google-map',
	props: {
		assetLocationOverlayStr: {
			type: String,
			required: true,
		},
		company: {
			type: Object,
			required: true
		},
		companyOptions: {
			type: Array,
			required: true,
		},
		storageLocationOptions: {
			type: Array,
			required: true,
		},
		assetTypeOptions: {
			type: Array,
			required: true,
		},
		allConnectionsObj: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			center: {},
			markers: [],
			filterBy: {
				company: { ...config.companyDefaultValue },
				storageLocation: { ...config.storageLocationDefaultValue },
                assetType: { ...config.assetTypeDefaultValue },
            },
			mapConfig: {
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: true,
				disableDefaultUi: false,
			},
			currentLocation: {
				isGettingLocation: false,
				location: null,
				error: null,
			},
			connectionsObj: {},
			filteredAssetTypeOptions: [],
			filteredCompanyOptions: [],
			filteredLocationOptions: [],
			
			assetLocationOverlay: null,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			isSuperAdmin: this.$store.getters.isSuperAdmin,
		};
	},
	computed: {
		assetLocationFilteredOptions() {
			return this.getAssetLocationOptions();
		}
	},
	watch: {
		assetLocationFilteredOptions: {
			handler(value) {
				this.loadMarkers(value);
			},
			immediate: true,
		},
		company() {
			if (this.company && !_.isEmpty(this.company)) {
				this.updateAssetTypeOptions(this.company.id);
				this.updateCompanyOptions(this.company.id);
			}
		},
		"filterBy.company"() {
			let companyId = this.filterBy.company && this.filterBy.company.id ? this.filterBy.company.id : null;
			this.updateLocationOptions(companyId);

			if (companyId) {	
				this.$nextTick(
					() => (this.center = this.setPinOnCenterView(this.markers, companyId))
				);
			}
			this.filterBy.storageLocation =  { ...config.storageLocationDefaultValue };
		},
	},
	async mounted() {
		if (!_.isEmpty(this.assetLocationOverlayStr)) {
			this.assetLocationOverlay = JSON.parse(this.assetLocationOverlayStr);

			if (this.company && !_.isEmpty(this.company)) {
				this.updateAssetTypeOptions(this.company.id);
				this.updateCompanyOptions(this.company.id);
			}
		}

		await this.findMyLocation();
	},
	methods: {
		updateAssetTypeOptions(companyId) {
			if (companyId !== null) {
				this.filteredAssetTypeOptions = _.filter(this.assetTypeOptions, o => {
					return o.value !== null && (o.value.originId === companyId || o.value.id === null);
				});
			} else {
				this.filteredAssetTypeOptions = [{
					value:  { ...config.assetTypeDefaultValue },
					text: ' - Please select - '
				}];
			}
		},
		getConnections(companyId) {
			let filteredConnections = [];

			filteredConnections = _.filter(this.allConnectionsObj, o => {
				return o.companyId === companyId;
			});

			if (!_.isEmpty(filteredConnections)) {
				return _.keyBy(filteredConnections, 'id');
			} else {
				return {};
			}
		},
		updateCompanyOptions(companyId) {
			this.connectionsObj = this.getConnections(companyId);
			let connectedCompanyIds = _.map(this.connectionsObj, 'connectedCompanyId');
			this.filteredCompanyOptions = _.filter(this.companyOptions, o => {
				return o.value.id === companyId || connectedCompanyIds.includes(o.value.id) || o.value.id === null;
			});
		},
		updateLocationOptions(companyId) {
			if (companyId !== null) {
				this.filteredLocationOptions = _.filter(this.storageLocationOptions, o => {
					return o.value && (o.value.companyId === companyId || o.value.id === null);
				});
			} else {
				this.filteredLocationOptions = [{
					value:  { ...config.storageLocationDefaultValue },
					text: ' - Please select - '
				}];
			}
		},
		getTotal(marker) {
			return !marker.assetDistribution.total ? 0 : marker.assetDistribution.total;
		},
		getAssetLocationOptions() {
			let data = [];

			if (!_.isEmpty(this.assetLocationOverlay)) {
				data = this.assetLocationOverlay;

				if (this.filterBy.assetType && this.filterBy.assetType.id) {
					data = data.filter((x) => {
						let hasAssetType = _.find(x.assetTypes, o => {
							return o.assetTypeId === this.filterBy.assetType.id;
						});
						return !_.isEmpty(hasAssetType);
					});
				}

				if (this.filterBy.company && this.filterBy.company.id) {
					data = this.assetLocationOverlay.filter((x) => x.connectedCompanyId === this.filterBy.company.id);
				}

				if (this.filterBy.storageLocation && this.filterBy.storageLocation.id) {
					data = data.filter((x) => x.connectedStorageLocationId === this.filterBy.storageLocation.id);
				}
			}

			return data;
		},
		loadMarkers(markers) {
			// reset all markers
			this.markers = [];

			if (markers && !_.isEmpty(markers)) {
				for (const marker of markers) {
					this.addMarker(marker);
				}
			}

			this.closeAllInfoWindows();
		},
		addMarker(marker) {
			let geoaddress = marker.geoaddress;

			let geolocation = {
				lat: geoaddress.latitude,
				lng: geoaddress.longitude,
			};

			this.markers.push({
				position: geolocation,
				assetDistribution: marker,
				visible: false,
				label: {
					text: marker.connectedStorageLocation,
					color: '#4285F4',
					fontSize: '12px',
					fontWeight: 'bold',
				},
			});

			this.center = geolocation;
		},
		toggleInfoWindow(index) {
			let prevValue = this.markers[index].visible;
			this.markers[index].visible = !prevValue;
		},
		closeInfoWindow(index) {
			this.toggleInfoWindow(index);
		},
		closeAllInfoWindows() {
			for (const marker of this.markers) {
				if (marker.visible) marker.visible = false;
			}
		},
		setPinOnCenterView(markers, companyId) {
			let centerMarkerCoordinates = {};

			for (const item of markers) {
				let distribution = item.assetDistribution;
				if (this.isSuperAdmin) {
					if (distribution.companyId === distribution.connectedCompanyId) {
						centerMarkerCoordinates = {
							lat: distribution.geoaddress.latitude,
							lng: distribution.geoaddress.longitude,
						};
						break;
					}
				} else {
					let lat = parseFloat(distribution.geoaddress.latitude);
					let lng = parseFloat(distribution.geoaddress.longitude);

					if ((companyId && companyId === distribution.connectedCompanyId)
						|| distribution.companyId === distribution.connectedCompanyId) {
						centerMarkerCoordinates = { lat, lng };
						break;
					}
				}
			}

			return centerMarkerCoordinates;
		},
		async findMyLocation() {
			this.currentLocation.isGettingLocation = true;
			try {
				this.currentLocation.isGettingLocation = false;
				this.currentLocation.location = await this.$getCurrentLocation();
				const lat = parseFloat(this.currentLocation.location.lat);
				const lng = parseFloat(this.currentLocation.location.lng);
				this.center = { lat, lng };

				if (this.markers.length > 0 && this.company.id) {
					this.center = this.setPinOnCenterView(this.markers, this.company.id);
				}
			} catch (error) {
				this.currentLocation.isGettingLocation = false;

				switch (error.code) {
					case error.PERMISSION_DENIED:
						this.currentLocation.error =
							'User denied the request for Geolocation.';
						break;
					case error.POSITION_UNAVAILABLE:
						this.currentLocation.error =
							'Location information is unavailable. Please try to enable your Location Settings.';
						break;
					case error.TIMEOUT:
						this.currentLocation.error =
							'The request to get user location timed out. Please try to enable your Location Settings.';
						break;
					case error.UNKNOWN_ERROR:
						this.currentLocation.error = 'An unknown error occurred.';
						break;
				}
			}
		},
	},
};
</script>

<style scoped>
.total-assets {
	margin-top: 5px;
}

.distribution {
	margin-top: 5px;
}

.field-without-label {
	margin-top: 30px;
}
</style>